export const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Company Profile</h2>
          <p>
            As a known industry leader, we’re used to working with all types of
            customers – local or global, large or small. Exceptional quality is
            the foundation of our company, and is the driving force behind all
            decisions; whether it’s which materials to purchase or the best way
            to serve our customers. We work in a highly competitive field, so
            we’re constantly investing in technology and research to make sure
            we stay ahead of the curve. Our commitment to quality guarantees our
            success and your satisfaction.
          </p>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-6">
                  {" "}
                  {/* <i className={d.icon}></i> */}
                  <div className="service-desc">
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
